import React from 'react';
import ComingSoon from '../ComingSoon';

const Crate = () => {
    return (
        <div>
            <ComingSoon/>        
        </div>
    );
};

export default Crate;